var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('b-card',[_c('good-data-table',{ref:"employees",attrs:{"columns":_vm.columns,"no-serial":true,"total-column":'response.responseData.total',"data-column":'response.responseData.data',"api-endpoint":'/hrm/employees',"buttonLabel":'Add'},on:{"add-new-info":_vm.addEmployee}})],1),_c('b-sidebar',{attrs:{"id":"sidebar-right","title":_vm.$t('Filter'),"bg-variant":"white","right":"","shadow":""}},[_c('div',{staticClass:"px-3 py-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('username')}},[_c('b-form-input',{attrs:{"label":"Username","placeholder":_vm.$t('username')},model:{value:(_vm.Filter_username),callback:function ($$v) {_vm.Filter_username=$$v},expression:"Filter_username"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Employment type')}},[_c('v-select',{attrs:{"reduce":function (label) { return label.value; },"placeholder":_vm.$t('Employment type'),"options":[
                      {label: 'Full-time', value: 'full_time'},
                      {label: 'Part-time', value: 'part_time'},
                      {label: 'Self-employed', value: 'self_employed'},

                      {label: 'Freelance', value: 'freelance'},
                      {label: 'Contract', value: 'contract'},
                      {label: 'Internship', value: 'internship'},
                      {label: 'Apprenticeship', value: 'apprenticeship'},
                      {label: 'Seasonal', value: 'seasonal'} ]},model:{value:(_vm.Filter_employment_type),callback:function ($$v) {_vm.Filter_employment_type=$$v},expression:"Filter_employment_type"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Company')}},[_c('v-select',{attrs:{"reduce":function (label) { return label.value; },"placeholder":_vm.$t('Company'),"options":_vm.companies.map(function (companies) { return ({label: companies.name, value: companies.id}); })},model:{value:(_vm.Filter_company),callback:function ($$v) {_vm.Filter_company=$$v},expression:"Filter_company"}})],1)],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-button',{attrs:{"variant":"primary m-1","size":"sm","block":""},on:{"click":function($event){return _vm.Get_Employees(_vm.serverParams.page)}}},[_c('i',{staticClass:"i-Filter-2"}),_vm._v(" "+_vm._s(_vm.$t("Filter"))+" ")])],1),_c('b-col',{attrs:{"md":"6","sm":"12"}},[_c('b-button',{attrs:{"variant":"danger m-1","size":"sm","block":""},on:{"click":function($event){return _vm.Reset_Filter()}}},[_c('i',{staticClass:"i-Power-2"}),_vm._v(" "+_vm._s(_vm.$t("Reset"))+" ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }