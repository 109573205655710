<template>
  <div class="main-content">
    <b-card>
      <good-data-table 
        ref="employees" 
        :columns="columns" :no-serial="true"
        :total-column="'response.responseData.total'" 
        :data-column="'response.responseData.data'"
        :api-endpoint="'/hrm/employees'"
        @add-new-info="addEmployee" 
        :buttonLabel="'Add'"
      />
    </b-card>
    <!-- Multiple Filters -->
    <b-sidebar id="sidebar-right" :title="$t('Filter')" bg-variant="white" right shadow>
      <div class="px-3 py-2">
        <b-row>

          <!-- Username  -->
          <b-col md="12">
            <b-form-group :label="$t('username')">
              <b-form-input label="Username" :placeholder="$t('username')" v-model="Filter_username"></b-form-input>
            </b-form-group>
          </b-col>

            <!-- Employment Type  -->
          <b-col md="12">
            <b-form-group :label="$t('Employment type')">
              <v-select
                v-model="Filter_employment_type"
                :reduce="label => label.value"
                :placeholder="$t('Employment type')"
                :options="
                      [
                        {label: 'Full-time', value: 'full_time'},
                        {label: 'Part-time', value: 'part_time'},
                        {label: 'Self-employed', value: 'self_employed'},

                        {label: 'Freelance', value: 'freelance'},
                        {label: 'Contract', value: 'contract'},
                        {label: 'Internship', value: 'internship'},
                        {label: 'Apprenticeship', value: 'apprenticeship'},
                        {label: 'Seasonal', value: 'seasonal'},
                      ]"
              ></v-select>
            </b-form-group>
          </b-col>

        
          <!-- Company  -->
          <b-col md="12">
            <b-form-group :label="$t('Company')">
              <v-select
                :reduce="label => label.value"
                :placeholder="$t('Company')"
                v-model="Filter_company"
                :options="companies.map(companies => ({label: companies.name, value: companies.id}))"
              />
            </b-form-group>
          </b-col>

          <b-col md="6" sm="12">
            <b-button
              @click="Get_Employees(serverParams.page)"
              variant="primary m-1"
              size="sm"
              block
            >
              <i class="i-Filter-2"></i>
              {{ $t("Filter") }}
            </b-button>
          </b-col>
          <b-col md="6" sm="12">
            <b-button @click="Reset_Filter()" variant="danger m-1" size="sm" block>
              <i class="i-Power-2"></i>
              {{ $t("Reset") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;

export default {
  metaInfo: {
    title: "Employee"
  },
  components: {
    GoodDataTable
  },
  data() {
    return {
      isLoading: true,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      Filter_username: "",
      Filter_employment_type: "",
      Filter_company: "",
      employees: [],
      companies: [],
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("First Name"),
          field: "firstname",
          
        },
        {
          label: this.$t("Last Name"),
          field: "lastname",
          
        },
        {
          label: this.$t("Phone"),
          field: "phone",
          
        },
        {
          label: this.$t("Department"),
          field: "department_name",
          
        },
        {
          label: this.$t("Designation"),
          field: "designation_name",
          
        },
        {
          label: this.$t("Office Shift"),
          field: "office_shift_name",
          
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('viewitem'),
              text: 'View',
              action: props => {
                this.$router.push({
                  name: `${this.routeNamePrefix}-detail_employee`,
                  params: {
                    id: props.id
                  }
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.$router.push({
                  name: `${this.routeNamePrefix}-edit_employee`,
                  params: {
                    id: props.id
                  }
                })
              },
            },
          ],
        },
      ];
    },
    routeNamePrefix: {
      get(){
        if(window.SuperApp.getters.isStore()) return 'shop';
        if(window.SuperApp.getters.isProvider()) return 'provider';
      }
    }
  },

  methods: {
    //---------------------- Employee PDF -------------------------------\\
    Employee_PDF() {
      var self = this;

      let pdf = new jsPDF("p", "pt");
      let columns = [
        { title: "First Name", dataKey: "firstname" },
        { title: "Last Name", dataKey: "lastname" },
        { title: "Phone", dataKey: "phone" },
        { title: "Company", dataKey: "company_name" },
        { title: "Department", dataKey: "department_name" },
        { title: "Designation", dataKey: "designation_name" },
        { title: "Office shift", dataKey: "office_shift_name" }
      ];
      pdf.autoTable(columns, self.employees);
      pdf.text("Employee List", 40, 25);
      pdf.save("Employee_List.pdf");
    },


    //------ update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    addEmployee(){
      this.$router.push({
        name: `${this.routeNamePrefix}-store_employee`,
      })
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.Get_Employees(currentPage);
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.Get_Employees(1);
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = [];
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id);
      });
    },

    //------ Event Sort Change
    onSortChange(params) {
      let field = "";
      if (params[0].field == "department_name") {
        field = "department_id";
      }else if (params[0].field == "designation_name") {
        field = "designation_id";
      } else {
        field = params[0].field;
      }
      this.updateParams({
        sort: {
          type: params[0].type,
          field: field
        }
      });
      this.Get_Employees(this.serverParams.page);
    },

    //------ Event Search
    onSearch(value) {
      this.search = value.searchTerm;
      this.Get_Employees(this.serverParams.page);
    },

    //------ Reset Filter
    Reset_Filter() {
      this.search = "";
      this.Filter_username = "";
      this.Filter_employment_type = "";
      this.Filter_company = "";
      this.Get_Employees(this.serverParams.page);
    },

    // Simply replaces null values with strings=''
    setToStrings() {
      if (this.Filter_employment_type === null) {
        this.Filter_employment_type = "";
      } else if (this.Filter_company === null) {
        this.Filter_company = "";
      }
    },

    //------------------------------------------------ Get All Expense -------------------------------\\
    Get_Employees(page) {
      // Start the progress bar.
      this.setToStrings();
      axios
        .get(
          apiUrl+"/employees?page=" +
            page +
            "&username=" +
            this.Filter_username +
            "&employment_type=" +
            this.Filter_employment_type +
            "&SortField=" +
            this.serverParams.sort.field +
            "&SortType=" +
            this.serverParams.sort.type +
            "&search=" +
            this.search +
            "&limit=" +
            this.limit
        )
        .then(response => {
          this.employees = response.data.employees;
          this.companies = response.data.companies;
          this.totalRows = response.data.totalRows;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Remove Employee -------------------------\\

    Remove_Employee(id) {
      axios
        .delete(apiUrl+"/employees/" + id)
        .then(() => {
          showSuccessNotification(this, 'Deleted successfully!');
          Fire.$emit("Delete_Employee");
        })
        .catch(() => {
          // Complete the animation of theprogress bar.
          showErrorNotification(this, "Something went wrong while deleting item!");
        });
    },

    //---- Delete Expense by selection

    delete_by_selected() {
      axios
        .post(apiUrl+"/employees/delete/by_selection", {
          selectedIds: this.selectedIds
        })
        .then(() => {
          showSuccessNotification(this, 'Deleted successfully!');

          Fire.$emit("Delete_Employee");
        })
        .catch(() => {
          // Complete the animation of theprogress bar.
          showErrorNotification(this, "Something went wrong while deleting item!");
        });
    }
  },

  //----------------------------- Created function-------------------
  mounted: function() {
    this.$refs.employees.loadItems();

    Fire.$on("Delete_Employee", () => {
      setTimeout(() => {
        // Complete the animation of theprogress bar.
        this.$refs.employees.loadItems();
      }, 500);
    });
  }
};
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
